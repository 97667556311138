import CompanyLogoFC from '../assets/svg/company-fc.svg';
import CompanyLogoFCWhite from '../assets/svg/company-fc-white.svg';
import CompanyLogoArtis from '../assets/svg/company-artis.svg';
import CompanyLogoArtisWhite from '../assets/svg/company-artis-white.svg';
import CompanyLogoX8C from '../assets/svg/company-x8c.svg';
import CompanyLogoX8CWhite from '../assets/svg/company-x8c-white.svg';
import CompanyLogoFS from '../assets/svg/company-fs.svg';
import CompanyLogoFSWhite from '../assets/svg/company-fs-white.svg';
import CompanyBgFC from '../assets/png/company-bg-fc.png';
import CompanyBgFS from '../assets/png/company-bg-fs.png';
import CompanyBgArtis from '../assets/png/company-bg-artis.png';
import CompanyBgX8C from '../assets/png/company-bg-x8c.png';
import CompanyBgKasu from '../assets/png/company-bg-kasu.png';
import CompanyBgPerq from '../assets/png/company-bg-perq.jpg';
import CompanyBgWayex from '../assets/png/company-bg-wayex.png';
import CompanyLogoKasuWhite from '../assets/svg/company-kasu-white.svg';
import CompanyLogoKasu from '../assets/svg/company-kasu.svg';
import CompanyLogoPerqWhite from '../assets/svg/company-perq-white.svg';
import CompanyLogoPerq from '../assets/svg/company-perq.svg';
import CompanyLogoWayexWhite from '../assets/svg/company-wayex-white.svg';
import CompanyLogoWayex from '../assets/svg/company-wayex.svg';

// export const BLOG_TAG_TYPE = {
//     ALL: 99,
//     NEWS: 3,
//     PODCAST: 4,
//     FEATURED: 5,
//     INSIGHTS: 6,
//     RESEARCH: 7,
// };

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const PROJECT_TAG_TYPE = {
    FEATURED: 8,
};

export const navigation = [
    {
        label: 'home.title',
        link: '/',
    },
    {
        label: 'story.title-menu',
        link: '/story',
    },
    // {
    //     label: 'solution.title-menu',
    //     link: '/solution',
    // },
    {
        label: 'portfolio.title',
        link: '/portfolio',
    },
    // {
    //     label: 'blog.title',
    //     link: '/blog',
    // },
    {
        label: 'contact.title',
        link: '/contact',
    },
];

export const statistics = [
    {
        prefix: '',
        number: 500,
        suffix: '+',
        text: 'home.statistics.text1',
    },
    {
        prefix: '',
        number: 11,
        suffix: '',
        text: 'home.statistics.text2',
    },
    {
        prefix: '$',
        number: 130,
        suffix: 'm',
        text: 'home.statistics.text3',
    },
    {
        prefix: '$',
        number: 42,
        suffix: 'b',
        text: 'home.statistics.text4',
    },
    {
        prefix: '',
        number: 200,
        suffix: '+',
        text: 'home.statistics.text5',
    },
];

export const companies = [
    {
        label: 'Venture Capital',
        logo: CompanyLogoFC,
        logoWhite: CompanyLogoFCWhite,
        img: CompanyBgFC,
        link: 'https://www.facultycapital.com/',
        title: 'Faculty Capital',
        desc1: 'Smart Capital & Advisory.',
        desc2: 'Deploying capital is just the beginning. We unite a wealth of resources to fully support projects on their path to success.',
        background: 'linear-gradient(219deg, #29C2E2, #0000FF)',
    },
    {
        label: 'Marketing & PR',
        logo: CompanyLogoX8C,
        logoWhite: CompanyLogoX8CWhite,
        img: CompanyBgX8C,
        link: 'https://x8c.io/',
        title: 'X8C',
        desc1: 'Growth Marketing & PR.',
        desc2: 'A Web3 marketing agency offering unique and tailored strategies to fuel and accelerate organic growth. \n\nOur team of marketing professionals provide innovative solutions that projects need to scale and thrive.',
        background: 'linear-gradient(37deg, #009DBF, #71D1E6)',
    },
    {
        label: 'Market Making',
        logo: CompanyLogoArtis,
        logoWhite: CompanyLogoArtisWhite,
        img: CompanyBgArtis,
        link: 'https://artis.systems/',
        title: 'Artis',
        desc1: 'Market Making & Treasury Management.',
        desc2: 'Artis provides leading secondary market solutions via a proprietary market making platform structured to meet specific client needs.',
        background: 'linear-gradient(43deg, #B70742, #EE2E71)',
    },
    {
        label: 'PERQ',
        logo: CompanyLogoPerq,
        logoWhite: CompanyLogoPerqWhite,
        img: CompanyBgPerq,
        link: 'https://perq.finance',
        title: 'PERQ',
        desc1: 'PERQ Finance',
        desc2: 'Drip brings Supercharged Launchpools to Ethereum and Arbitrum. Stake your idle Stablecoins or ETH in PERQ Pools to earn your favorite tokens.',
        background: 'linear-gradient(220deg, #D29CFC 1.57%, #C6F4AA 104.48%)',
    },
    {
        label: 'Wayex',
        logo: CompanyLogoWayex,
        logoWhite: CompanyLogoWayexWhite,
        img: CompanyBgWayex,
        link: 'https://www.wayex.com',
        title: 'Wayex',
        desc1: 'The New Way Of Crypto.',
        desc2: 'Wayex is a digital currency platform based in Australia that enables users to buy, sell, and spend cryptocurrencies. It offers a Visa card for seamless spending of both crypto and cash, access to various cryptocurrencies, and purchases through PayID/Osko.',
        background: 'radial-gradient(141.42% 141.42% at 100% 0%, #F5FAFF 0%, #8D9EB4 100%)',
    },
    {
        label: 'Kasu',
        logo: CompanyLogoKasu,
        logoWhite: CompanyLogoKasuWhite,
        img: CompanyBgKasu,
        link: 'https://kasu.finance',
        title: 'Kasu',
        desc1: 'Kasu Finance',
        desc2: 'Kasu is a decentralized platform that connects investors with real-world business borrowers. It offers risk optimised yield opportunities by tapping into real-world business borrowers, providing real-world cash flows and security.',
        background: 'linear-gradient(225deg, #E9D2AA 0%, #AE8759 100%)',
    },
    {
        label: 'Venture Builder',
        logo: CompanyLogoFS,
        logoWhite: CompanyLogoFSWhite,
        img: CompanyBgFS,
        link: 'https://spool.fi/',
        title: 'Faculty Studio',
        desc1: 'Faculty Studio.',
        desc2: 'Venture Studio purpose-built to ideate, build and launch tools for Web3.\n\nFounding contributors to Spool DAO.',
        background: 'linear-gradient(0deg, #5CB5F7, #5CB5F7)',
    },
];
