import React from 'react';

const MenuIcon = ({ width = 32, height = 32, fill = 'white', ...rest }) => {
    return (
        <svg
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32.999 33"
            fill={fill}
            {...rest}
        >
            <g id="Group_6222" data-name="Group 6222" transform="translate(-1273 -57)">
                <g id="Group_6221" data-name="Group 6221">
                    <g id="Group_6220" data-name="Group 6220">
                        <path
                            id="Union_7"
                            data-name="Union 7"
                            d="M-1402,29.5a3.5,3.5,0,0,1,3.5-3.5,3.5,3.5,0,0,1,3.5,3.5,3.5,3.5,0,0,1-3.5,3.5A3.5,3.5,0,0,1-1402,29.5Zm1,0a2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.5-2.5,2.5,2.5,0,0,1-2.5,2.5,2.5,2.5,0,0,1-2.5-2.5Zm12-13a3.5,3.5,0,0,1,3.5-3.5,3.5,3.5,0,0,1,3.5,3.5,3.5,3.5,0,0,1-3.5,3.5A3.5,3.5,0,0,1-1389,16.5Zm1,0a2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.5-2.5,2.5,2.5,0,0,1-2.5,2.5,2.5,2.5,0,0,1-2.5-2.5Zm-14,0a3.5,3.5,0,0,1,3.5-3.5,3.5,3.5,0,0,1,3.5,3.5,3.5,3.5,0,0,1-3.5,3.5A3.5,3.5,0,0,1-1402,16.5Zm1,0a2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.5-2.5,2.5,2.5,0,0,1-2.5,2.5,2.5,2.5,0,0,1-2.5-2.5Zm-14,0a3.5,3.5,0,0,1,3.5-3.5,3.5,3.5,0,0,1,3.5,3.5,3.5,3.5,0,0,1-3.5,3.5A3.5,3.5,0,0,1-1415,16.5Zm1,0a2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.5-2.5,2.5,2.5,0,0,1-2.5,2.5,2.5,2.5,0,0,1-2.5-2.5Zm12-13a3.5,3.5,0,0,1,3.5-3.5,3.5,3.5,0,0,1,3.5,3.5,3.5,3.5,0,0,1-3.5,3.5A3.5,3.5,0,0,1-1402,3.5Zm1,0a2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.5-2.5,2.5,2.5,0,0,1-2.5,2.5,2.5,2.5,0,0,1-2.5-2.5Z"
                            transform="translate(2688 57)"
                            className="transition"
                            fill={fill}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default MenuIcon;
